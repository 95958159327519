export const entity = [
  "Public Limited",
  "Private Limited",
  "Proprietorship",
  "Hindu Undivided Family",
  "Partnership",
  "Limited Liability Partnership",
  "Enterprises",
  "Sole Proprietorship",
  "One person Company",
];

export const position = [
  "Director",
  "Partner",
  "Proprietor",
  "Other",
  "Not sure",
];
